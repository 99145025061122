// @flow
import * as React from 'react'
import styled from 'styled-components'
import { ms } from 'modules/browser/const'
import ty from 'utils/typographie'

type Props = {
  children: string | any
  centered?: boolean
  bordered?: boolean
  verticalCentered?: boolean
  className?: string
}

export default function Markdown({ children, ...rest }: Props) {
  return <Wrapper className="Markdown" {...rest} dangerouslySetInnerHTML={{ __html: children }} />
}

const Wrapper = styled.div`
  line-height: 1.57;

  ${(props) =>
    props.centered &&
    `
    text-align:center;
  `}
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
  `}
  ${(props) =>
    props.verticalCentered &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  /* text */
  p, li {
    color: #262626;
    ${ty([14, 0.42, 24], 'Open Sans')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([16, 0.48, 24])}
    }
    em {
    }
    a strong {
      color: #555;
    }
    strong {
    }
    svg {
      width: 16px;
    }
  }
  a {
    overflow-wrap: anywhere;
    > button {
      cursor: pointer;
      padding: 5px 10px;
      color: white;
      display: inline-block;
      background: #555;
      &:hover {
        color: white;
      }
      &:focus {
        color: white;
      }
    }
  }
  a.button-like {
    padding: 10px;
    color: white;
    display: inline-block;
    background: #555;
    text-decoration: none;
    &:hover {
      color: white;
    }
    &:focus {
      color: white;
    }
  }
  button {
    border: none;
    padding: 0;
    background: none;
    font: unset;
  }
  p > button:only-child ~ p {
    text-align: center;
  }

  /* quotes */
  blockquote {
    background: #eeeced;
    margin: 0;
    padding: 20px 30px;

    em {
      font-size: 0.8em;
      display: block;
      text-align: center;
      margin: 0px auto;
    }
  }

  /* anchors */
  .h-wrapper {
    position: relative;
    > .anchor {
      position: absolute;
      margin-top: -50px;
      @media (min-width: ${ms.LAPTOP}px) {
        margin-top: -85px;
      }
    }
  }

  a,
  a:focus,
  a:hover {
    color: #555;
    text-decoration: underline;
  }

  a:focus {
    outline-style: dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  h1 {
    ${ty([30, 0.54, 42], 'Cormorant Garamond', '500')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([38, 0.74, 52])}
    }
    color: #262626;
    text-align: center;
    max-width: 680px;
    margin: 0 auto;
  }

  h2 {
    ${ty([26, 0.46, 36], 'Cormorant Garamond', '500')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([32, 0.62, 46])}
    }
    color: #262626;
  }

  h3 {
    ${ty([22, 0.84, 32], 'Cormorant Garamond', '500')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([26, 0.46, 36])}
    }
    color: #262626;
  }

  h4,
  h5,
  h6 {
    ${ty([14, 3.05, 24], 'Raleway', 'normal')}
    @media (min-width: ${ms.LAPTOP}px) {
      ${ty([16, 3.5, 28])}
    }
    color: #262626;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2,
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h4,
  h5,
  h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p ~ .h-wrapper,
  blockquote ~ .h-wrapper,
  ul ~ .h-wrapper {
    h1,
    h2,
    h3 {
      margin-top: 60px;
      margin-bottom: 28px;
    }

    h4,
    h5,
    h6 {
      margin-top: 30px;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  th {
    font-weight: bold;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    ${ty([14, 0.42, 24], 'Open Sans')}
  }
`
